var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"beanstalk"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-database-outline")]),_vm._v("Databse Health Status ")],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"title mb-2"},[_vm._v("Atlas Prod "),_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Good")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.health",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.health),"dark":""}},[_vm._v(" "+_vm._s(item.health)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"title mb-2"},[_vm._v("Solar Prod "),_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Good")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.health",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.health),"dark":""}},[_vm._v(" "+_vm._s(item.health)+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"mb-6"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"title mb-2"},[_vm._v("Mongo Test "),_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Good")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.health",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.health),"dark":""}},[_vm._v(" "+_vm._s(item.health)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"title mb-2"},[_vm._v("Mongo Prod "),_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Good")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.health",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.health),"dark":""}},[_vm._v(" "+_vm._s(item.health)+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }