<template>
  <div class="dashboard">
    <v-carousel
      continuous
      cycle
      dark
      interval="50000"
      v-model="model"
      show-arrows-on-hover
      height="1200"
    >
      <v-carousel-item>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-title>
                  <v-icon class="mr-2">mdi-food-apple-outline</v-icon> Appetite Calls
                </v-card-title>
                <v-card-text>
                  <div class="display-1 font-weight-light">{{ appetite_calls | numFormat }}</div>
                  <small>{{ runtime | numFormat('0.000') }}s avg. call length</small>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    Showing activity as of
                    {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-card-title>
                  <v-icon class="mr-2">mdi-card-account-details-outline</v-icon> Profile Calls
                </v-card-title>
                <v-card-text>
                  <div class="display-1 font-weight-light">{{ profile_calls | numFormat }}</div>
                  <small>{{ runtime | numFormat('0.000') }}s avg. call length</small>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    Showing activity as of
                    {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-card-title>
                  <v-icon class="mr-2">mdi-file-pdf-outline</v-icon> PDF Calls
                </v-card-title>
                <v-card-text>
                  <div class="display-1 font-weight-light">{{ pdf_calls | numFormat }}</div>
                  <small>{{ runtime | numFormat('0.000') }}s avg. call length</small>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    Showing activity as of
                    {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-title>
                  <v-icon class="mr-2">mdi-alert-outline</v-icon> Gateway Errors
                </v-card-title>
                <v-card-text>
                  <div class="display-1 font-weight-light">{{ gateway_errors | numFormat }}</div>
                  <small>{{ total_errors | numFormat  }} total</small>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    Showing activity as of
                    {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-card-title>
                  <v-icon class="mr-2">mdi-timer-outline</v-icon> Avg. Call Length
                </v-card-title>
                <v-card-text>
                  <div class="display-1 font-weight-light">{{ runtime | numFormat('0.000') }}s</div>
                  <small style="color:#20E799">
                    min: {{ runtime_min | numFormat('0.000')  }}s
                  </small>
                  <small style="color:#DF0505">
                    max: {{ runtime_max | numFormat('0.000')  }}s
                  </small>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    Showing activity as of
                    {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <v-card-title>
                  <v-icon class="mr-2">mdi-speedometer</v-icon> Response Time
                </v-card-title>
                <v-card-text>
                  <div class="display-1 font-weight-light">{{ runtime | numFormat('0.000') }}s</div>
                  <small style="color:#20E799">
                    min: {{ runtime_min | numFormat('0.000')  }}s
                  </small>
                  <small style="color:#DF0505">
                    max: {{ runtime_max | numFormat('0.000')  }}s
                  </small>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    Showing activity as of
                    {{ Date().toLocaleString() | moment("dddd, MMMM Do YYYY") }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <BeanstalkHealth />
            </v-col>
            <v-col>
              <DatabaseHealth />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                    <vue-weather
                      api-key="a66ceae26d52d9abd2daa0361e0f5c00"
                      language="en"
                      units="us"
                    >
                    </vue-weather>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->
        </v-container>
      </v-carousel-item>

      <v-carousel-item>
        <v-container>
          <CurrentMonthlyActivity client="Ascot" />
        </v-container>
      </v-carousel-item>

      <v-carousel-item>
        <v-container>
          <UserCalls client="Ascot" />
        </v-container>
      </v-carousel-item>

      <!-- <v-carousel-item>
        <v-container>
          <v-card>
            <v-card-title>AWS Health</v-card-title>
            <v-card-text>
              <div class="display-1 font-weight-light">{{ 0 | numFormat }}</div>
            </v-card-text>
          </v-card>
        </v-container>
      </v-carousel-item> -->
    </v-carousel>
  </div>
</template>

<script>
// import VueWeather from 'vue-weather-widget';
import getActivity from '@/services/getActivity';
import UserCalls from '@/components/activity/UserCalls.vue';
import CurrentMonthlyActivity from '@/components/activity/CurrentMonthlyActivity.vue';
import BeanstalkHealth from '../components/dashboard/BeanstalkHealth.vue';
import DatabaseHealth from '../components/dashboard/DatabaseHealth.vue';

export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Dashboard',
  },
  data: () => ({
    client: 'Ascot',
    model: 0,
    loading: true,
    appetite_calls: 0,
    profile_calls: 0,
    pdf_calls: 0,
    runtime: 0,
    runtime_min: 0,
    runtime_max: 0,
    gateway_errors: 0,
    total_errors: 0,
  }),
  components: {
    // VueWeather,
    UserCalls,
    CurrentMonthlyActivity,
    BeanstalkHealth,
    DatabaseHealth,
  },
  methods: {
    getActivity() {
      this.loading = true;
      getActivity('today', '')
        .then((results) => {
          this.appetite_calls = results.calls.product.appetite;
          this.profile_calls = results.calls.product.profile;
          this.pdf_calls = results.calls.product.pdf;

          this.runtime = results.calls.runtime.median / 1000;
          this.runtime_min = results.calls.runtime.min / 1000;
          this.runtime_max = results.calls.runtime.max / 1000;
          this.gateway_errors = results.errors.gateway;
          this.total_errors = results.errors.total;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
    },
  },
  mounted() {
    this.getActivity();
  },
};
</script>

<style scoped>
.dashboard {
  background: #1E1E1E;
  height: auto;
  width: 100%;
}
</style>
