<template>
  <div class="beanstalk">
    <v-card outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-database-outline</v-icon>Databse Health Status
      </v-card-title>
      <v-container fluid>
        <v-row class="mb-6">
          <v-col cols="12" md="6">
            <h2 class="title mb-2">Atlas Prod <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6">
            <h2 class="title mb-2">Solar Prod <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mb-6">
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="title mb-2">Mongo Test <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6">
            <h2 class="title mb-2">Mongo Prod <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
               <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      solr: [],
      headers: [
        {
          text: 'DB',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Health Status', value: 'health' },
        { text: 'Processors', value: 'processors' },
        { text: 'Memory Free', value: 'free' },
        { text: 'Memory Total', value: 'total' },
        { text: 'Memory Max', value: 'max' },
        { text: 'Memory Used', value: 'used' },
      ],
      desserts: [
        {
          name: 'Primary Database',
          health: 'Good',
          processors: 2,
          free: '2.4 GB',
          total: '3.8 GB',
          max: '3.8 GB',
          used: '1.5 GB (%38.4)',
        },
      ],
    };
  },
  methods: {
    getColor(health) {
      if (health > 400) return 'red';
      if (health > 200) return 'orange';
      return 'green';
    },
    getHealthData() {
      const config = {
        method: 'get',
        url: 'http://52.21.191.141:10164/solr/admin/info/system', // Solr http://52.21.191.141:10176/solr/admin/info/system
        headers: {
        },
      };

      axios(config)
        .then((response) => {
          this.solr = response.data.jvm;
          console.log(response.data); // eslint-disable-line
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
        });
    },
  },
  mounted() {
    this.getHealthData();
  },
};
</script>
