<template>
  <div class="beanstalk">
    <v-card outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-robot-happy-outline</v-icon> Beanstalk Enviroment Health Status
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="3">
            <h2 class="title mb-2">Test <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="3">
            <h2 class="title mb-2">Prod <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="3">
            <h2 class="title mb-2">Batch Test <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="3">
            <h2 class="title mb-2">Batch Prod <v-chip color="green" dark>Good</v-chip></h2>
            <v-data-table
              hide-default-footer
              disable-pagination
              :headers="headers"
              :items="desserts"
              class="elevation-1"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.health="{ item }">
                <v-chip
                  :color="getColor(item.health)"
                  dark
                >
                  {{ item.health }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      headers: [
        {
          text: 'App',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Health Status', value: 'health' },
      ],
      desserts: [
        {
          name: 'prometheus-Batch',
          health: 159,
        },
        {
          name: 'prometheus-sandbox',
          health: 237,
        },
        {
          name: 'prometheus-test',
          health: 262,
        },
        {
          name: 'midas-test',
          health: 305,
        },
        {
          name: 'prometheus',
          health: 356,
        },
        {
          name: 'scylla-Batch',
          health: 375,
        },
        {
          name: 'kratos2-Batch-test',
          health: 392,
        },
        {
          name: 'kratos2-Batch',
          health: 408,
        },
        {
          name: 'eb-custom-platform-builder-packer',
          health: 452,
        },
        {
          name: 'hydra-custom-test',
          health: 518,
        },
      ],
    };
  },
  methods: {
    getColor(health) {
      if (health > 400) return 'red';
      if (health > 200) return 'orange';
      return 'green';
    },
    getHealthData() {
      const config = {
        method: 'get',
        url: 'https://elasticbeanstalk-health.us-east-1.amazonaws.com',
        headers: {
          'X-Amz-Date': '20210722T201114Z',
          Authorization: 'AWS4-HMAC-SHA256 Credential=AKIAUAK6ZZINAT5ZAYSI/20210722/us-east-1/awseb-healthd/aws4_request, SignedHeaders=host;x-amz-date, Signature=cd4f202794e8323dc392c22016e4c92367e6fb8fbf996082154cf56bb1b2ce25',
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data); // eslint-disable-line
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
        });
    },
  },
  mounted() {
    this.getHealthData();
  },
};
</script>
